/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'

// Setup font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faPaperPlane as falPaperPlane,
  faHandshake as falHandshake,
  faMegaphone as falMegaphone,
  faBooks as falBooks,
  faAnalytics as falAnalytics,
  faArrowRight as falArrowRight,
  faPuzzlePiece as falPuzzlePiece,
  faUniversity as falUniversity,
  faUsersCrown as falUsersCrown,
  faUsers as falUsers,
  faLockAlt as falLockAlt,
  faLightbulbOn as falLightbulbOn,
  faUsdCircle as falUsdCircle,
  faHandHoldingUsd as falHandHoldingUsd,
  faAward as falAward,
  faCalendarAlt as falCalendarAlt,
  faPhone as falPhone,
  faFileSignature as falFileSignature,
  faExchange as falExchange,
  faCheckSquare as falCheckSquare,
  faCommentsAlt as falCommentsAlt,
  faFileContract as falFileContract,
  faFileAlt as falFileAlt,
  faSearchLocation as falSearchLocation,
  faGavel as falGavel,
  faCalendarDay as falCalendarDay,
  faChartNetwork as falChartNetwork,
  faCalendarCheck as falCalendarCheck,
  faBuilding as falBuilding,
  faCogs as falCogs,
  faChartArea as falChartArea,
  faPeopleCarry as falPeopleCarry,
  faPiggyBank as falPiggyBank,
  faTasksAlt as falTasksAlt,
  faInfoCircle as falInfoCircle
} from '@fortawesome/pro-light-svg-icons'
import { faArrowRight as farArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { faCaretUp as fasCaretUp } from '@fortawesome/pro-solid-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

// Import custom components
import Header from 'components/navigation/header'

// Create font awesome library
library.add([
  falPaperPlane, faLinkedinIn, falHandshake, falMegaphone, falBooks, falAnalytics, 
  falArrowRight, farArrowRight, fasCaretUp, falPuzzlePiece, falUniversity, falUsers, falUsersCrown,
  falLockAlt, falLightbulbOn, falUsdCircle, falHandHoldingUsd, falAward, falUsers, falCalendarAlt,
  falPhone, falFileSignature, falExchange, falCheckSquare, falCommentsAlt, falFileContract, falFileAlt,
  falSearchLocation, falGavel, falCalendarDay, falChartNetwork, falCalendarCheck, falBuilding, falCogs,
  falChartArea, falPeopleCarry, falPiggyBank, falTasksAlt, falInfoCircle
])

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <main>
        { children }
      </main>
    </div>
  )
}

export default Layout
