import React, { useState, useRef, forwardRef, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import classnames from 'classnames'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import MobileDetect from 'mobile-detect'

import Logo from 'components/navigation/logo'
import Navmenu from 'components/navigation/navmenu'
import styles from './header.module.css'

const query = graphql`
  query {
    backgroundImage: file(relativePath: { eq: "hex-bg.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const MobileNavigationButton = ({ isOpen = false, handleClick, enabled = false }) => {
  if (enabled) {
    return (
      <div className={styles.navContainer}>
        <span className={classnames(styles.navText, isOpen && styles.navTextOpen)}>{ isOpen ? 'CLOSE' : 'MENU' }</span>
        <span className={styles.navDecorator}></span>
        <div className={classnames(styles.icon, isOpen && styles.iconOpen)} onClick={() => handleClick(!isOpen)}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    )
  }

  return null
}

const MobileNavigation = forwardRef(({ handleClick }, ref) => {
  const data = useStaticQuery(query)
  const image = data.backgroundImage.childImageSharp.fluid

  return (
    <div className={styles.navMobileContainer}>
      <Img fluid={image} className={styles.navImage} imgStyle={{ height: '100%', width: 'auto', opacity: 0.5 }} />

      <div className={styles.navLinksWrapper}>
        <ul ref={ref}>
          <li><AniLink fade to='/what-we-do' onClick={() => handleClick(false)}>What We Do</AniLink></li>
          <li><AniLink fade to='/transitions' onClick={() => handleClick(false)}>Transitions</AniLink></li>
          <li><AniLink fade to='/process' onClick={() => handleClick(false)}>Process</AniLink></li>
          <li><AniLink fade to='/why-array' onClick={() => handleClick(false)}>Why Array</AniLink></li>
          <li><AniLink fade to='/contact' onClick={() => handleClick(false)}>Contact</AniLink></li>
        </ul>
      </div>

      <div className={styles.navSocial}>
        <AniLink fade to='/careers' className={styles.navSocialLink} onClick={() => handleClick(false)}>Careers</AniLink>
        <a href='https://www.linkedin.com/company/array-dental-group' target='_blank' rel='noopener noreferrer' className={styles.navSocialIcon}>
          <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
        </a>
        <AniLink fade to='/sitemap' className={styles.navSocialLink} onClick={() => handleClick(false)}>Sitemap</AniLink>
      </div>
    </div>
  )
})

const Header = () => {
  const [mobileNavigationOpen, openMobileNav] = useState(false)
  const [isMobileOrTablet, setMobile] = useState(false)
  const mobileNav = useRef(null)

  useEffect(() => {
    const userAgent = typeof window !== 'undefined' ?  window.navigator.userAgent.toString() : ''
    const md = new MobileDetect(userAgent)

    if (md.phone() || md.tablet()) {
      setMobile(true)
    }
  }, [])

  const handleClick = (open) => {
    openMobileNav(open)

    if (open) {
      document.documentElement.style.overflow = 'hidden'
      disableBodyScroll(mobileNav.current)
    } else {
      document.documentElement.style.overflow = ''
      enableBodyScroll(mobileNav.current)
    }
  }

  return (
    <header className={styles.container}>
      <Logo white={mobileNavigationOpen} isMobileMenuOpen={mobileNavigationOpen} handleClick={handleClick} />
      { isMobileOrTablet ? null : <Navmenu /> }
      <MobileNavigationButton isOpen={mobileNavigationOpen} handleClick={handleClick} enabled={isMobileOrTablet} />
      { mobileNavigationOpen && <MobileNavigation ref={mobileNav} handleClick={handleClick} /> }
    </header>
  )
}

export default Header
