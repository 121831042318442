import React, { useRef, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TweenMax, Power3 } from 'gsap'
import classnames from 'classnames'

import styles from './navmenu.module.css'

const query = graphql`
  query HeaderMenu {
    allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "primary-menu"}}) {
      edges {
        node {
          items {
            order
            title
            url
            attr
            target
            type_label
            classes
            object_slug
            wordpress_children {
              wordpress_id
              order
              wordpress_parent
              title
              url
              object_id
              object_slug
              wordpress_children {
                object_id
                object_slug
                order
                title
                url
                wordpress_id
                wordpress_parent
              }
            }
          }
        }
      }
    }
  }
`

const NavItem = ({ object_slug, title, onMouseEnter, type_label, url }) => (
  <li className={styles.menuItem}>
    {
      type_label === 'Custom Link' 
      ? <a href={url} target='_blank' className={classnames(styles.menuLink, 'header-link', 'underline-animation')}>{ title }</a>
      : <AniLink 
          fade
          to={`/${object_slug}`} 
          className={classnames(styles.menuLink, 'header-link', 'underline-animation')}
          activeClassName={styles.menuLinkActive}
          partiallyActive={true}
          onMouseEnter={onMouseEnter}
        >
          { title }
          <FontAwesomeIcon icon={['fas', 'caret-up']} className={styles.menuLinkIcon} />
        </AniLink>
    }
  </li>
)

const NavDecorator = ({ children }) => <li className={classnames(styles.menuItem, styles.menuDecorator)}>{ children }</li>

const NavDropdown = ({ object_slug, title, wordpress_children, handleHover }) => {
  const [ height, setHeight ] = useState(null)
  const contentRef = useRef(null)

  const content = wordpress_children.map(parent => {
    let children = null

    if (parent.wordpress_children) {
      children = parent.wordpress_children.map(child => {
        return (
          <li className={styles.dropdownLinkItem} key={child.object_slug}>
            <AniLink 
              fade
              to={`/${object_slug}/${parent.object_slug}/${child.object_slug}/`} 
              className={classnames(styles.dropdownLink, 'underline-animation')}
              partiallyActive={true}
              dangerouslySetInnerHTML={{ __html: child.title }}
              trigger={() => handleHover(null, null)}
            >
            </AniLink>
          </li>
        )
      })
    }

    return (
      <div className={styles.dropdownSection} key={parent.object_slug}>
        <h3 className={styles.dropdownTitle}>
          <AniLink
            fade
            to={`/${object_slug}/${parent.object_slug}/`} 
            className={styles.dropdownTitleLink}
            activeClassName={styles.menuLinkActive}
            partiallyActive={true}
            trigger={() => handleHover(null, null)}
            dangerouslySetInnerHTML={{ __html: parent.title }}
          ></AniLink>
        </h3>
        <span className={styles.dropdownSmallAccent}></span>
        <ul className={styles.dropdownLinkGroup}>
          { children }
        </ul>
      </div>
    )
  })

  useEffect(() => {
    setHeight(contentRef.current.offsetHeight)
  }, [])

  return (
    <li className={styles.menuItem}>
      <AniLink 
        fade
        to={`/${object_slug}`} 
        className={classnames(styles.menuLink, 'header-link', 'underline-animation')}
        activeClassName={styles.menuLinkActive}
        partiallyActive={true}
        onMouseEnter={(e) => {
          handleHover(object_slug, content, height)
        }}
        trigger={() => {
          handleHover(null, null)
        }}
      >
        { title }
        <FontAwesomeIcon icon={['fas', 'caret-up']} className={styles.menuLinkIcon} />
      </AniLink>

      { !height && <div className={styles.dropdownContent} style={{ opacity: 0, width: '100vw' }} ref={contentRef}>{ content }</div> }
    </li>
  )
}
const Navmenu = () => {
  // Standard Menu Items
  const data = useStaticQuery(query);
  const { items } = data.allWordpressWpApiMenusMenusItems.edges[0].node
  const split = Math.ceil(items.length / 2)
  let leftMenu = items.slice(0, split)
  let rightMenu = items.slice(split)

  leftMenu = [].concat(...leftMenu.map(e => ['.:.', e])).slice(1)
  rightMenu = [].concat(...rightMenu.map(e => ['.:.', e])).slice(1)

  leftMenu = leftMenu.map(item => {
    if (typeof item === 'string') {
      return <NavDecorator key={Math.random()}>{ item }</NavDecorator>
    } else {
      if (item.wordpress_children) {
        return <NavDropdown key={item.object_slug} {...item} handleHover={openDrawer} />
      } else {
        return <NavItem key={item.object_slug} {...item} onMouseEnter={() => closeDrawer()} />
      }
    }
  })

  rightMenu = rightMenu.map(item => {
    if (typeof item === 'string') {
      return <NavDecorator key={Math.random()}>{ item }</NavDecorator>
    } else {
      return <NavItem key={item.object_slug} {...item} onMouseEnter={() => closeDrawer()} />
    }
  })

  // Dropdown Specific
  const [parentHeight, setParentHeight] = useState(0)
  const [dropdownChildren, setDropdownChildren] = useState(null)
  const [currentDrawerID, setDrawerID] = useState(null)
  const backdropRef = useRef(null)
  const dropdownRef = useRef(null)
  const headerRef = useRef(null)
  const contentRef = useRef(null)

  useEffect(() => {
    setParentHeight(105)
  }, [])

  function openDrawer(ID, children, height = 0) {
    if (ID !== currentDrawerID) {
      setDrawerID(ID)
      setDropdownChildren(children)
      open(height)
    }

    if (ID === null && children === null) {
      closeDrawer()
    }
  }

  function closeDrawer() {
    setDrawerID(null)
    close()
  }

  const open = (calculatedHeight) => {
    const height = parentHeight + calculatedHeight

    TweenMax.killTweensOf([contentRef.current, dropdownRef.current, backdropRef.current])

    TweenMax.set(contentRef.current, { opacity: 0 })
    TweenMax.to(dropdownRef.current, 0.6, { height, opacity: 1, ease: Power3.easeInOut })
    TweenMax.to(backdropRef.current, 0.8, { opacity: 0.75, ease: Power3.easeInOut })
    TweenMax.to(contentRef.current, 0.6, { opacity: 1, delay: 0.5 })
  }

  const close = () => {
    TweenMax.to(contentRef.current, 0.3, { opacity: 0 })
    TweenMax.to(dropdownRef.current, 0.6, { height: 105, opacity: 0, ease: Power3.easeInOut, delay: 0.15 })
    TweenMax.to(backdropRef.current, 0.6, { opacity: 0, delay: 0.15, ease: Power3.easeInOut, onComplete: () => setDropdownChildren(null) })
  }

  return (
    <div className={styles.container} ref={headerRef} onMouseLeave={() => closeDrawer()}>
      <div className={styles.dropdown} ref={dropdownRef}>
        <div className={styles.dropdownContent} ref={contentRef}>
        { dropdownChildren }
        </div>

        <span className={styles.dropdownAccent}>
          <span>.:.</span>
        </span>
      </div>

      <div 
        ref={backdropRef}
        style={{
          backgroundColor: '#0f2236',
          height: '100vh',
          left: 0,
          opacity: 0,
          pointerEvents: 'none',
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: -5
        }}
      ></div>

      <ul className={styles.menu}>{ leftMenu }</ul>
      <span className={styles.separator}></span>
      <ul className={styles.menu}>{ rightMenu }</ul>
    </div>
  )
}

export default Navmenu