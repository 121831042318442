import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Img from 'gatsby-image'

import styles from './logo.module.css'

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 320, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    whiteVersion: file(relativePath: { eq: "logo_white.png" }) {
      childImageSharp {
        fluid(maxWidth: 320, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const Logo = ({ handleClick, isMobileMenuOpen = false, white = false }) => {
  const data = useStaticQuery(query)
  const image = data.placeholderImage.childImageSharp.fluid
  const whiteImage = data.whiteVersion.childImageSharp.fluid

  return (
    <div className={styles.logo}>
      <AniLink
        aria-label='Return to Array Dental Group home page'
        to='/' 
        fade
        onClick={() => isMobileMenuOpen ? handleClick(false) : null}
      >
        { 
          white
          ? <Img fluid={whiteImage} alt={'Array Dental Group'} />
          : <Img fluid={image} alt={'Array Dental Group'} />  
        }
      </AniLink>
    </div>
  )
}

export default Logo